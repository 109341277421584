<template>
  <div>
    <div class="detail-head">
      <div class="dh-body">
        <Avatar v-if="!shop.imageUrl" icon="ios-person" size="70" style="margin: 30px auto"/>
        <img v-else :src="shop.imageUrl" class="dhb-icon" alt="">
        <div class="dhb-des">
          <div class="dhb-des-title">
            <div class="dhb-des-title-text ellipse-1">{{ shop.name }}</div>
            <div class="dhb-des-title-tag display-flex-center">已认证</div>
          </div>
          <div class="dhb-des-tel">电话：{{ shop.contactPhone ? shop.contactPhone : '暂无' }}</div>
          <div class="dhb-des-tel">地址：暂无</div>
          <div class="dhb-rate">
            <div>
              <span style="margin-right: 5px">服务质量</span>
              <Rate disabled :value="shop.serviceQuality||5"/>
            </div>
            <div style="margin-left: 20px">
              <span style="margin-right: 5px">服务态度</span>
              <Rate disabled :value="shop.serviceAttitude||5"/>
            </div>
            <div style="margin-left: 20px">
              <span style="margin-right: 5px">响应速度</span>
              <Rate disabled :value="shop.serviceSpeed||5"/>
            </div>
          </div>
        </div>
        <div class="dhb-num">商品数：<span style="color: rgba(254, 121, 48, 1)">{{ shop.goodsCount || 0 }}</span>件
        </div>
        <div class="dhb-right">
          <img
              src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/51miz-E769910-F2561733%402x.png"
              class="dhb-right-img"/>
          <div class="dhb-right-button display-flex-center" @click="goShop">进入店铺</div>
        </div>
      </div>
      <div class="dh-bottom">
        <img
            src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_%E5%BA%97%E9%93%BA%E7%AE%80%E4%BB%8B%402x.png"
            class="dh-bottom-left"
        />
        <div class="dh-bottom-blank"/>
        <div class="dh-bottom-right ellipse-2">
          {{ shop.introduction || '暂无简介' }}
        </div>
      </div>
    </div>

    <div class="detail-des">
      <img class="dd-img" :src="imgIndexUrl"/>
      <div class="dd-select">
        <div class="dds-button display-flex-center" @click="changeImageIndex(false)">
          <Icon type="md-arrow-dropup"/>
        </div>
        <div class="dds-body">
          <img
              :class="['dds-img','',imgIndex === index ? 'select_img' : '',]"
              :src="item.url" alt="" v-for="(item,index) in goodsImage"
              :key="index"
              @click="chooseImage(index)"/>
        </div>
        <div class="dds-button display-flex-center" @click="changeImageIndex(true)">
          <Icon type="md-arrow-dropdown"/>
        </div>
      </div>
      <div class="dd-right">
        <div class="ddr-title ellipse-1">{{ goods.name }}</div>
        <div class="ddr-classify dp-flex">服务分类：<span class="ddr-tagA display-flex-center">
          {{ classify }}
        </span></div>
        <div class="dp-flex" style="overflow: auto">
          <div
              v-for="(item,index) in labelSpuVos" :key="index + item.id"
              class="ddr-classify dp-flex">{{ item.name }}：<span
              v-for="(child,cindex) in item.values" :key="cindex" @click="changeLabel(index,cindex)"
              :class="['ddr-tagB','display-flex-center',labelClass[index] === cindex ? 'classify-area-choose' : '',]"
              class="ddr-tagB display-flex-center">
              {{ child.name }}
                  </span></div>
        </div>
        <div class="ddr-classify dp-flex">服务价格：<span class="ddr-price display-flex-center">
       {{ goodsStock.price === 0 ? "面议" : `￥${goodsStock.price}` }} </span></div>

        <div class="ddr-bottom dp-flex">
          <div class="ddr-bottom-buy display-flex-center"   @click="goPay">立即购买</div>
          <div class="ddr-bottom-chat display-flex-center" @click="returnRoom">立即咨询</div>
          <div
              v-if="cFlag"
              class="ddr-bottom-chat display-flex-center" @click="collectBtn">关注
          </div>
          <div v-if="!cFlag" class="ddr-bottom-focus display-flex-center" @click="collectBtn">
            <Icon type="md-heart-outline"/>
            已关注
          </div>
        </div>
      </div>
    </div>

    <div class="detail-tab">
      <div class="dp-flex" style="background-color: rgba(201, 226, 251, 1);">
        <div
            @click="changeBottomIndex(index)"
            :class="['display-flex-center',bottomIndex === index ? 'dt-tab-select' : 'dt-tab',]"
            v-for="(item,index) in bottomList" :key="index">{{ item }}
        </div>
      </div>
      <div style="min-height: 600px">
        <div style="padding: 35px" v-show="bottomIndex===0" v-html="goodsDetail.goodsDetail || '暂无信息'"
             class="html-box"></div>
        <div style="padding: 35px" v-show="bottomIndex===1" v-html="goodsDetail.serviceDetail || '暂无信息'"
             class="html-box"></div>
        <div v-show="bottomIndex===2" style="padding:0 40px">
          <div class="evaluate-label dp-flex" style="line-height: 50px">
            <div @click="changeEvaluateTab('')" style="cursor: pointer">全部评价（{{ evaluateSum }}）</div>
            <div @click="changeEvaluateTab(3)" style="cursor: pointer">好评（{{ evaluateSum3 }}）</div>
            <div @click="changeEvaluateTab(2)" style="cursor: pointer">中评（{{ evaluateSum2 }}）</div>
            <div @click="changeEvaluateTab(1)" style="cursor: pointer">差评（{{ evaluateSum1 }}）</div>
          </div>
          <div v-for="(item,index) in evaluateList" :key="index" class="dp-flex evaluate-item flex-between"
               style="border-bottom: 1px solid #ddd; padding: 50px 0">
            <div style="width: 135px">
              <div>
                <Rate disabled v-model="item.evaluateValue"/>
              </div>
              <div>{{ item.createdTime }}</div>
            </div>
            <div style="width: 715px">
              <div style="line-height: 26px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap"
                   :title="item.evaluateContent">{{ item.evaluateContent }}
              </div>
              <div
                  style="line-height: 26px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;font-size: 12px"
                  :title="item.replyContent"><span v-if="item.replyContent">回复：</span> {{ item.replyContent || "暂无回复" }}
              </div>
            </div>
            <div>{{ item.userName }}</div>
          </div>
          <div STYLE="overflow: hidden;width:100%;margin: 10px 0">
            <Page
                :total="evaluateSum"
                :page-size="evaluateQuery.pageSize"
                show-elevator
                style="margin-top: 30px; float: right; margin-bottom: 30px"
                @on-change="changeEvaluatePage"
            />
          </div>
        </div>
        <div v-show="bottomIndex===3" style="width: 870px;margin-left: 100px;padding-top: 30px">
          <div v-for="(item,index) in commentList" :key="index" style="border-bottom: 1px dashed #ddd;">
            <div style="font-size: 18px;line-height: 20px;margin: 5px auto 10px;">
              <span style="color: #FF6A00;">{{ item.reviewUserName }}:</span>
              <span>{{ item.reviewContent }}</span>

            </div>
            <div style="font-size: 16px;line-height: 20px;margin: 5px auto 10px;">
              <span style="color: #FF6A00;">{{ item.shopName }}:</span>
              <span>{{ item.replyContent }}</span>
            </div>
          </div>
          <div v-if="commentList.length === 0" style="padding-top: 15px">
            暂无咨询
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  collect,
  isCollect
} from "@/plugins/api/collect";
import {
  sendComment,
  getComment, goodsRest
} from "@/plugins/api/Good";
import {
  getEvaluateClassify,
  getScienceServiceDetail,
  getScienceServiceEvaluateList,
  getGoodsId
} from "../../../plugins/api/scienceServiceApi";
import {getLocalToken} from "../../../plugins/tools/storage";
import {errorToast, successToast} from "../../../plugins/tools/util";

export default {
  name: "ServiceDetail",
  data() {
    return {
      // 关注按钮文字
      cStr: "关注",
      cFlag: true,
      detail: {},
      commentsIndex: 1,
      imgIndex: 0,
      imgIndexUrl: "",
      bottomIndex: 0,
      classifyIndex: 0,
      areaIndex: 0,
      bottomList: [
        "商品详情", "服务详情", "商品评价", "商品咨询"
      ],
      valueDisabled: 2,
      goodId: 0,
      goods: "",
      goodsDetail: "",
      goodsImage: [],
      labels: [],
      labelClass: [],
      labelSpuVos: [],
      classifyLables: [],
      areaLables: [],
      goodsStock: "",
      classify: "",
      shop: "",
      textareaVal: "",
      commentList: [],
      evaluateList: [],
      evaluateQuery: {
        pageSize: 8,
        pageNum: 1,
        evaluateLevel: ""
      },
      evaluateSum: 0,
      evaluateSum1: 0,
      evaluateSum2: 0,
      evaluateSum3: 0
    };
  },
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler(to, from) {
        if (JSON.stringify(to.query) !== "{}") {
          this.goodId = to.query.goodId;
          this.getGoodDetail(to.query.goodId);
          this.isCollect(this.goodId);
        }
      }
    }
  },
  methods: {
    collectBtn() {
      this.checkLogin();
      getLocalToken() && collect({
        typeId: this.goodId,
        type: 3,
        content: JSON.stringify(this.detail)
      }).then((data) => {
        if (data.code === 0) {
          this.cFlag = !this.cFlag;
          this.cStr = this.cFlag ? "关注" : "取消关注";
        }
      });
    },
    isCollect(id) {
      getLocalToken() && isCollect({typeId: id, type: 3}).then((data) => {
        this.cFlag = data.result;
        this.cStr = this.cFlag ? "关注" : "取消关注";
      });
    },
    changeLabel(index, cindex) {
      this.$set(this.labelClass, index, cindex);

    },
    gotostore(shopId, shopTypeId) {
      this.$router.push({
        path: "OutsideStore",
        query: {
          shopId: shopId
        }
      });
    },
    checkLogin() {
      if (!getLocalToken()) {
        this.$Modal.confirm({
          draggable: true,
          onOk: async () => {
            this.$router.push({name: "login"});
          },
          render: (h) => {
            return h("div",
                [h("div", {
                  style: "text-align:center;margin-bottom:20px;padding-top:20px;vertical-align: middle;font-size: 16px;color: #17233d;"
                }, "请先登录后再试")
                ]);
          }
        });
        return false;
      } else {
        return true;
      }
    },
    returnRoom: function () {
      const state = this.checkLogin();
      if (!state) {
        return;
      }

      this.$Modal.confirm({
        draggable: true,
        onOk: async () => {
          this.sendComment();
        },
        render: (h) => {
          return h("div",
              [h("div", {
                style: "margin-bottom:20px;vertical-align: middle;font-size: 16px;color: #17233d;font-weight: 700;"
              }, "咨询内容"),
                h("Input", {
                  style: "width:100%;",
                  props: {
                    value: this.textareaVal,
                    autofocus: true,
                    placeholder: "请输入咨询内容",
                    type: "textarea",
                    autosize: true
                  },
                  on: {
                    input: (val) => {
                      this.textareaVal = val;
                    }
                  }
                })
              ]);
        }
      });
    },
    //发送咨询
    sendComment() {
      sendComment({
        goodsId: this.goodId,
        reviewContent: this.textareaVal
      }).then(res => {
        if (res.code === 0) {
          this.textareaVal = "";
          successToast("咨询成功");
        }
        // this.$Modal.success({
        //   title: '提示',
        //   content: '发送成功'
        // });
      });
    },
    //商品详情
    getGoodDetail(goodId) {
      getScienceServiceDetail({"goodsId": goodId}).then((res) => {
        if (res.code === 0) {
          this.detail = res.result;
          this.goods = res.result.goods;
          this.spu = res.result.goods.spu;
          this.classify = res.result.classifyTwo;
          this.labels = res.result.labels;
          this.labelSpuVos = res.result.labelSpuVos;
          for (let i = 0; i < this.labelSpuVos.length; i++) {
            for (let j = 0; j < this.labelSpuVos[i].values.length; j++) {
              if (this.labelSpuVos[i].valueId == this.labelSpuVos[i].values[j].id) {
                this.labelClass.push(j);
                break;
              }
            }

          }
          this.goodsDetail = res.result.goodsDetail;
          this.goodsImage = res.result.goodsImage;
          this.imgIndexUrl = res.result.goods.mainImageUrl;
          this.goodsStock = res.result.goodsStock;
          if (res.result.shop) {
            this.shop = res.result.shop;
            if (!this.shop.serviceQuality) {
              this.shop.serviceQuality = 5;
            }
            if (!this.shop.serviceAttitude) {
              this.shop.serviceAttitude = 5;
            }
            if (!this.shop.serviceSpeed) {
              this.shop.serviceSpeed = 5;
            }
          }

        }
      });
    },
    // 评价列表
    async getServiceEvaluate(param) {
      let pm = Object.assign({}, param, {goodsId: this.goodId});
      getScienceServiceEvaluateList(pm).then(res => {
        if (res.code === 0) {
          this[`evaluateSum${this.evaluateQuery.evaluateLevel}`] = res.result.total;
          this.evaluateList = res.result.list;
        }
      });
      let data = {
        goodsId: this.goodId
      };
      let json = await getEvaluateClassify(data);
      if (json.code == 0) {
        this.evaluateSum3 = json.result.level3;
        this.evaluateSum1 = json.result.level1;
        this.evaluateSum2 = json.result.level2;
        this.evaluateSum = Number(json.result.level3) + Number(json.result.level2) + Number(json.result.level1);
      }
    },
    changeEvaluatePage(index) {
      this.evaluateQuery.pageNum = index;
      let param = Object.assign({}, this.evaluateQuery);
      this.getServiceEvaluate(param);
    },
    changeEvaluateTab(level) {

      this.evaluateQuery = {
        evaluateLevel: level,
        pageSize: 8,
        pageNum: 1
      };
      this.getServiceEvaluate(this.evaluateQuery);
    },
    //咨询留言列表
    getComment(goodId) {
      getComment({"goodsId": goodId}).then((res) => {
        if (res.code === 0) {
          this.commentList = res.result.list;
        }
      });
    },
    changeBottomIndex(index) {
      if (index == 2 || index == 3) {
        const state = this.checkLogin();
        if (!state) {
          return;
        }
        index == 2 && this.getServiceEvaluate({goodsId: this.goodId});
        index == 3 && this.getComment(this.goodId);
      }
      this.bottomIndex = index;
    },
    chooseComments(index) {
      this.commentsIndex = index;
    },
    chooseImage(index) {
      this.imgIndex = index;
      this.imgIndexUrl = this.goodsImage[this.imgIndex].url;
    },
    changeImageIndex(flag) {
      let max = this.goodsImage.length - 1;
      if (flag === true) {
        if (this.imgIndex < max) {
          this.imgIndex += 1;
        } else {
          this.imgIndex = 0;
        }
      }
      if (flag === false) {
        if (this.imgIndex !== 0) {
          this.imgIndex -= 1;
        } else {
          this.imgIndex = max;
        }
      }
      this.imgIndexUrl = this.goodsImage[this.imgIndex].url;

    },
    async goPay() {
      let value = "";
      for (let i = 0; i < this.labelSpuVos.length; i++) {
        value += this.labelSpuVos[i].id + "," + this.labelSpuVos[i].values[this.labelClass[i]].id + ";";
      }
      value = value.slice(0, value.length - 1);
      let data = {
        spu: this.spu,
        labelIdAndValue: value
      };
      let pjson = await getGoodsId(data);
      if (pjson.code == 0) {

        let goodId = pjson.result;
        let json = await goodsRest({goodsId: goodId, num: 1});
        if (json.code == 0) {
          if (json.result == true) {
            this.$router.push({
              path: `servicePay?goodId=${goodId}`
            });
          } else {
            errorToast(json.message);
          }
        } else {
          errorToast(json.message);
        }
      } else {
        errorToast(pjson.message);
      }
    },
    goShop(){
      this.$router.push({
        path: 'serviceOutStoreList',
        query: {
          shopId:this.shop.id
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
.detail-head {
  width: 1200px;
  height: 243px;
  background: linear-gradient(180deg, rgba(42, 139, 239, 0.29), rgba(255, 255, 255, 0));
  border-radius: 6px;
  box-shadow: 1px 1px 10px rgba(229, 239, 249, 1);
  margin: 30px auto 20px auto;
}

.dh-body {
  height: 180px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(228, 228, 228, 1);
  padding: 29px 37px 29px 20px;
}

.dh-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 63px;
}

.dh-bottom-left {
  width: 81px;
  height: 18px;
  margin-left: 20px;
}

.dh-bottom-blank {
  width: 1px;
  height: 69px;
  margin-left: 37px;
  background: linear-gradient(0deg, #000000 0%, #FFFFFF 0%, #FFFFFF 0%, #C6DFFF 47%, #FFFFFF 100%);
}

.dh-bottom-right {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  padding-left: 40px;
  padding-right: 80px;
}

.dhb-icon {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.dhb-des {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 28px;
  overflow: hidden;
  width: 643px;
}

.dhb-des-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dhb-des-title-text {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
}

.dhb-des-title-tag {
  width: 60px;
  height: 22px;
  background: #D7F1D9;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #17C22D;
  flex-shrink: 0;
  margin-left: 10px;
}

.dhb-des-tel {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}

.dhb-rate {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.dhb-num {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 70px;
  flex-shrink: 0;
}

.dhb-right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: flex-end;
  margin-left: 50px;
  position: relative;
}


.dhb-right-img {
  width: 121px;
  height: 128px;
}

.dhb-right-button {
  position: absolute;
  top: 23px;
  right: -20px;
  width: 120px;
  height: 34px;
  background: #0F62DF;
  border: 1px solid #0F62DF;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}

.detail-des {
  width: 1200px;
  height: 318px;
  background: #FFFFFF;
  border-radius: 6px;
  box-shadow: 1px 1px 10px rgba(229, 239, 249, 1);
  margin: 20px auto;
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.dd-img {
  width: 390px;
  height: 273px;
}

.dd-select {
  display: flex;
  flex-direction: column;
  margin-left: 9px;
}

.dds-body {
  flex: 1;
  overflow: auto;
}

.dds-img {
  width: 80px;
  height: 56px;
  margin-top: 10px;
  cursor: pointer;
}

.select_img {
  border: 1px solid rgba(15, 98, 223, 1);
}

.dds-button {
  width: 80px;
  height: 17px;
  background-color: #E4E9EF;
  color: rgba(158, 168, 181, 1);
  font-size: 20px;
  cursor: pointer;
}

.dds-button:hover {
  color: rgba(15, 98, 223, 1);
  background-color: rgba(207, 224, 249, 1);
}

.dd-right {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.ddr-title {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
}

.ddr-classify {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-top: 20px;
  flex-shrink: 0;
}

.ddr-tagA {
  width: 70px;
  height: 20px;
  background-color: #FFDBCD;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #FE7930;
}

.ddr-tagB {
  height: 20px;
  background-color: rgba(228, 233, 239, 1);
  border-radius: 4px;
  margin-right: 10px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #0F62DF;
  cursor: pointer;
}

.ddr-price {
  font-size: 14px;
  font-weight: 400;
  color: rgba(254, 121, 48, 1);
}

.classify-area-choose {
  background-color: rgba(202, 223, 254, 1);
}

.ddr-bottom {
  margin-top: 50px;
}

.ddr-bottom-buy {
  width: 120px;
  height: 34px;
  background: #FE7930;
  border: 1px solid #FE7930;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}

.ddr-bottom-chat {
  width: 120px;
  height: 34px;
  background: #FFFFFF;
  border: 1px solid #0F62DF;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #0F62DF;
  margin-left: 10px;
  cursor: pointer;
}

.ddr-bottom-focus {
  width: 120px;
  height: 34px;
  background: #DCEAFE;
  border: 1px solid #0F62DF;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #0F62DF;
  margin-left: 10px;
  cursor: pointer;
}


.detail-tab {
  width: 1200px;
  margin: 20px auto;
  box-shadow: 1px 1px 10px rgba(229, 239, 249, 1);
}

.dt-tab {
  width: 160px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  height: 50px;
  border-right: 1px solid #FFFFFF;
  cursor: pointer;
}

.dt-tab:hover {
  color: rgba(15, 98, 223, 1);
}

.dt-tab-select {
  width: 160px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  height: 50px;
  border-right: 1px solid #FFFFFF;
  cursor: pointer;
  background-color: rgba(15, 98, 223, 1);
}
</style>
<style>
textarea.ivu-input {
  height: 120px !important;
}
</style>
